<template>
  <a-modal
    v-model="pushModal"
    :width='800'
    title="挪动"
  >
    <template #footer>
      <a-button key="back" @click="cancelPushModal">取消</a-button>
      <a-button key="submit" type="primary" @click="confirmPushModal('sample')">挪动到样本库</a-button>
      <a-button key="submit" type="primary" @click="confirmPushModal('bottom')">更改底库版别</a-button>
    </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin($event, 'change')"
          @search="coinSearch($event, '', [])"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.coinKindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoinItem"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 220px"
          v-model="params.coinKindItemId"
          placeholder="请选择小币种"
        >
          <a-select-option
            v-for="item of coinItemList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          @change="changeItemVersion"
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 280px"
          v-model="params.coinKindItemVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
        >
          <a-select-option
            v-for="item of versionList"
            :key="item.coinVersionId"
            :value="item.coinVersionId"
          >{{ `${item.coinVersionName}【${item.size}】【${item.level}】` }}</a-select-option>
        </a-select>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
export default {
  mixins: [_bigOrSmallCoinByVersion],
  data() {
    return {
      untils,
      pushModal: false,
      params: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    pushModal() {
      if (!this.pushModal) {
        this.params = {};
      }
    },
  },
  methods: {
    async show(data) {
      this.$loading.show()
      this.params = JSON.parse(JSON.stringify(data))
      const { coinVersionId, coinKindName, coinKindItemName, coinVersionName } = data;
      this.params.coinKindItemName = coinKindItemName;
      this.params.coinKindItemVersionId = coinVersionId;
      this.params.coinKindItemVersionName = coinVersionName;
      await this.coinSearch(coinKindName, 'setDefault', [5])
      this.$loading.hide()
      this.pushModal = true;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal(type) {
      const {
        id,
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
      } = this.params;
      const url = type === 'bottom' ? '/dq_admin/duibanConfirm/editConfirmData' : '/dq_admin/duibanConfirm/confirmDataToSampleData'
      const res = await this.axios.post(url, {
        id,
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
      });
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>