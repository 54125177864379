<template>
  <div class="content">
    <div class="top">
      <a-input
        allowClear
        placeholder="关键词或id"
        v-model="params.keyword"
        @pressEnter="search"
        size="small"
        style="margin: 5px; width: 180px"
      />
      <!-- 选择大币种 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @search="getBigCoin"
        @change="changeVersionlistSearch"
        :filter-option="untils.filterOption"
        allowClear
        showSearch
        class="m-5 w-150"
        size="small"
        v-model="params.coinId"
        placeholder="请选择大币种"
      >
        <a-select-option
          v-for="item of bigCoinList"
          :key="item.coinId"
          :value="item.coinId"
        >{{ item.coinName }}</a-select-option>
      </a-select>
      <!-- 选择小币种 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @change="coinItemChange"
        :filter-option="untils.filterOption"
        allowClear
        showSearch
        size="small"
        style="margin: 5px; width: 150px"
        v-model="params.coinItemId"
        placeholder="请选择小币种"
      >
        <a-select-option
          v-for="item of smallCoinList"
          :key="item.coinKindItemId"
          :value="item.coinKindItemId"
        >{{ item.coinKindItemName }}</a-select-option>
      </a-select>
      <!-- 选择版别 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="search"
        allowClear
        showSearch
        :filter-option="untils.filterOption"
        size="small"
        style="margin: 5px; width: 200px"
        v-model="params.coinVersionId"
        :dropdownMenuStyle="{'max-height': '500px'}"
        placeholder="版别"
      >
        <a-select-option
          v-for="item of versionList"
          :key="item.coinVersionId"
          :value="item.coinVersionId"
        >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}</a-select-option>
      </a-select>
      <button
        class="search"
        @click="search"
      >搜索</button>
      <div class="change-box">
        <button
          class="search"
          @click="uploadClick()"
        >上传</button>
        <a-radio-group
          v-model="imageType"
          :default-value="0"
          button-style="solid"
        >
          <a-radio-button :value="0">
            正面图
          </a-radio-button>
          <a-radio-button :value="1">
            背面图
          </a-radio-button>
        </a-radio-group>
        <button
            v-if="false"
            class="download-btn"
            @click="handleDownload()"
        >对版准确度统计</button>
      </div>
    </div>
    <div class="table-content">
      <ConfirmList
        :list="data"
        :imageType="imageType"
        @delectSuccess="delectSuccess"
        @editSuccess="editSuccess"
        @smallVersion="handleShowSameVersion($event)"
        @cutPicSuccess="handleCutPicSuccess($event)"
      ></ConfirmList>
      <div class="pagination">
        <a-pagination
          :pageSize.sync="pagination.pageSize"
          :current="pagination.current"
          :total="pagination.total"
          @change="changePage"
        />

      </div>
    </div>
    <ConfirmAdd
      ref="confirmAdd"
      @success="addSuccess"
    />
    <ConfirmExportData ref="export-data"/>
  </div>
</template>
<script>
import ConfirmExportData from "@/views/cmsPage/versionManage/duibanconfirm/ConfirmExportData";
import ConfirmList from "@/views/cmsPage/versionManage/duibanconfirm/ConfirmList.vue";
import ConfirmAdd from "@/views/cmsPage/versionManage/duibanconfirm/ConfirmAdd.vue";
import untils from "@/untils";
import {getBigCoinList} from "@/axios/apis"

export default {
  components: {
    ConfirmList,
    ConfirmAdd,
    ConfirmExportData
  },
  data() {
    return {
      untils,
      innerTitle: "123",
      imageType: 0, //正面 1背面
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
      },
      params: {
        pageNum: 1,
        pageSize: 30,
        keyword: undefined,
        coinId: undefined,
        coinItemId: undefined,
        coinVersionId: undefined,
      },
      bigCoinList: [],
      smallCoinList: [],
      versionList: [],
      sameVersionData: ''
    };
  },
  async mounted() {
  },
  methods: {
    /** 裁剪成功 */
    async handleCutPicSuccess(id) {
      const res = await this.axios("/dq_admin/duibanConfirm/list", {
        params: {
          keyword: id
        }
      });
      if (res.status !== '200') return
      const tempData = res.data.records
      this.data.forEach(el => {
        if (el.id === id) {
          el.cutBackImage = tempData[0].cutBackImage
          el.cutFrontImage = tempData[0].cutFrontImage
        }
      })
    },
    /** 同币种 */
    async handleShowSameVersion(data) {
      this.params.keyword = undefined
      this.sameVersionData = data
      await this.getBigCoin(data.coinKindName)
      this.params.coinId = data.coinId
      await this.changeVersionlistSearch(data.coinId)
      this.params.coinItemId = data.coinItemId
      await this.getSmallCoinList(data.coinItemId)
      this.params.coinVersionId = data.coinVersionId
      await this.getVersion()
    },
    /** 下载文件 */
    handleDownload() {
      this.$refs['export-data'].show()
    },
    editSuccess() {
      this.addSuccess();
    },
    addSuccess() {
      if (this.params.coinId && this.params.coinItemId) {
        this.getVersion();
      }
      this.getList();
    },
    // 上传点击
    uploadClick() {
      this.$refs["confirmAdd"].show();
    },
    // 删除成功
    delectSuccess() {
      this.getList();
    },
    // 获取大币种
    async getBigCoin(str) {
      const res = await getBigCoinList(str, [5])
      if (res.status !== '200') return;
      this.bigCoinList = res.data.records.map(el => ({coinName: el.coinKindName, coinId: el.id}));
    },
    // 选择大币种
    async changeVersionlistSearch(val) {
      delete this.params.coinItemId;
      delete this.params.coinVersionId;
      if (!val) {
        this.smallCoinList = [];
        this.versionList = [];
        return;
      }
      await this.getSmallCoinList();
    },
    // 查询小币种
    async getSmallCoinList() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinItems", {
        params: {
          coinId: this.params.coinId,
        },
      });
      if (res.status != 200) return;
      this.smallCoinList = res.data;
    },
    // 选择小币种
    coinItemChange(val) {
      delete this.params.coinVersionId;
      if (!val) {
        this.versionList = [];
        return;
      }
      this.getVersion();
    },
    // 获取版别
    async getVersion() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinVersions", {
        params: {
          coinId: this.params.coinId,
          coinItemId: this.params.coinItemId,
        },
      });
      if (res.status != 200) return;
      this.versionList = res.data;
    },

    async getList() {
      const res = await this.axios("/dq_admin/duibanConfirm/list", {
        params: this.params,
      });
      if (res.status != 200) return;
      let list = res.data.records;
      list.forEach(item => {
        item.collect = 0
        item.backCollect = 0
      })
      this.data = list;
      this.pagination.total = res.data.total;
    },

    changePage(page) {
      this.pagination.current = page;
      this.params.pageNum = page;
      this.getList();
    },

    search(e) {
      this.params.page = 1;
      this.params.pageNum = 1;
      this.pagination.current = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.download-btn {
  margin-left: 10px;
  width: 130px !important;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    width: 65px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 10px;
    outline-style: none;
    cursor: pointer;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
