<template>
  <a-modal
    v-model="pushModal"
    :width='800'
    title="上传"
    ok-text="确认"
    cancel-text="取消"
    @cancel="cancelPushModal"
    @ok="confirmPushModal"
  >
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoinItem"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 220px"
          v-model="curItem.coinKindItemId"
          placeholder="请选择小币种"
        >
          <a-select-option
            v-for="item of coinItemList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          @change="changeVersion"
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 260px"
          v-model="curItem.coinKindItemVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
        >
          <a-select-option
            v-for="item of versionList"
            :key="item.id"
            :value="item.id"
          >{{ `${item.coinKindVersionName}` }}</a-select-option>
        </a-select>
      </div>
      <div class="images">
        <div class="images-title">
          <div>正</div>
          <div>反</div>
        </div>
        <div class="up-image">
          <div class="up-left">
            <a-upload
                :key="imgKey"
                :action="ip + '/file/upload'"
                list-type="picture-card"
                :file-list="coverList"
                multiple
                @preview="handlePreview"
                @change="handleChangeCoverList"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </div>
          <div class="up-right">
            <a-checkbox-group v-if="coverList" class="right-list"  @change="handleChangeCheckBoxText($event)">
              <a-checkbox
                  class="right-item"
                  v-for="(items, index) of parseInt(coverList.length / 2)"
                  :key="index"
                  :value="index"
              >是否作为测试集</a-checkbox>
            </a-checkbox-group>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import untils from '@/untils/index'
import {getBigCoinList} from "@/axios/apis"
export default {
  data() {
    return {
      pushModal: false,
      curItem: {},
      coverList: [],
      imgKey: "",
      coinList: [],
      coinItemList: [],
      versionList: [],
      checkBoxTest: [],
      untils: untils
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    pushModal() {
      if (!this.pushModal) {
        this.coverList = [];
        this.curItem = {};
      }
    },
  },
  mounted() {
    addEventListener("paste", (params) => {
      this.untils.pasteImg(params, 1, (a, b, s) => {
        this.coverList.push({
          uid: this.coverList.length + 1,
          name: "image",
          status: "done",
          url: b,
        });
      });
    });
  },
  methods: {
    parsetCallback(type, data, row) {
      if (this.coverList && this.coverList.length > 0) {
        this.coverList[0]["url"] = data;
      }
    },
    handleChangeCheckBoxText(event) {
      this.checkBoxTest = event
    },
    async show() {
      this.pushModal = true;

      const { coinId, coinItemId, coinVersionId } = this.$parent.params;
      if (!coinId) return;
      // 大币种列表
      const { bigCoinList } = this.$parent;
      const coin = bigCoinList.find((el) => {
        return el.coinId == coinId;
      });
      await this.coinSearch(coin.coinName);
      this.$set(this.curItem, "coinKindId", coinId);
      await this.changeCoin();
      if (!coinItemId) return;
      this.$set(this.curItem, "coinKindItemId", coinItemId);
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
      this.$set(this.curItem, "coinKindItemVersionId", coinVersionId);
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.coinKindItemVersionId;
      });
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
    },
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
    handlePreview(file) {
      const picturesList = this.coverList.map((row) => {
        return row.url;
      });
      const curIndex = picturesList.findIndex(el=>{
        return el == file.url
      })
      if (picturesList && picturesList.length > 0) {
        this.$previewImg({
          list: picturesList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          current: curIndex,
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    // 大币种改变
    async changeCoin() {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemName;
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!this.curItem.coinKindId) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.$set(this.curItem, "coinSid", coin.sid);
      this.$set(this.curItem, "coinKindName", coin.coinKindName);
      await this.coinItemSearch();
    },
    //  选择小币种
    changeCoinItem() {
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!this.curItem.coinKindItemId) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
    },
    // 版别改变
    changeVersion() {
      if (!this.curItem.coinKindItemVersionId) return;
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.coinKindItemVersionId;
      });
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await getBigCoinList(str, [5])
      if (res.status !== '200') return;
      this.coinList = res.data.records
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      const {
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
      } = this.curItem;
      const pushImages = [];
      let count = -1;
      this.coverList.forEach((el, index) => {
        if (index % 2 == 0) {
          count++;
          pushImages[count] = [];
        }
        pushImages[count].push(el.url);
      });
      pushImages.forEach((item, index, arr) => {
        if (this.checkBoxTest && this.checkBoxTest.length) {
          if (this.checkBoxTest.includes(index)) {
            arr[index].push(1)
          } else {
            arr[index].push(0)
          }
        } else {
          item.push(0)
        }
      })
      const coverImages = pushImages.map((el) => {
        return el.join(",");
      });
      const res = await this.axios.post("/dq_admin/ratingSplider/pushAsynDiku", {
        type: 2, // 手动上传
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
        coverImages,
      });
      if (res.status != 200) return;
      this.checkBoxTest = []
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.images {
  width: 350px;
  margin-top: 10px;
  .images-title {
    width: 208px;
    display: flex;
    margin-bottom: 10px;
    div {
      width: 104px;
      flex: 1;
      text-align: center;
      margin-right: 10px;
    }
  }
  .up-image {
    display: flex;
    .up-left {
      width: 350px;
      display: flex;
      flex-wrap: wrap;
    }
    .up-right {
      .right-list {
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      .right-item {
        width: 150px;
        height: 104px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>