var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticStyle: { margin: "5px", width: "180px" },
            attrs: { allowClear: "", placeholder: "关键词或id", size: "small" },
            on: { pressEnter: _vm.search },
            model: {
              value: _vm.params.keyword,
              callback: function($$v) {
                _vm.$set(_vm.params, "keyword", $$v)
              },
              expression: "params.keyword"
            }
          }),
          _c(
            "a-select",
            {
              staticClass: "m-5 w-150",
              attrs: {
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                allowClear: "",
                showSearch: "",
                size: "small",
                placeholder: "请选择大币种"
              },
              on: {
                search: _vm.getBigCoin,
                change: _vm.changeVersionlistSearch
              },
              model: {
                value: _vm.params.coinId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinId", $$v)
                },
                expression: "params.coinId"
              }
            },
            _vm._l(_vm.bigCoinList, function(item) {
              return _c(
                "a-select-option",
                { key: item.coinId, attrs: { value: item.coinId } },
                [_vm._v(_vm._s(item.coinName))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "150px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                allowClear: "",
                showSearch: "",
                size: "small",
                placeholder: "请选择小币种"
              },
              on: { change: _vm.coinItemChange },
              model: {
                value: _vm.params.coinItemId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinItemId", $$v)
                },
                expression: "params.coinItemId"
              }
            },
            _vm._l(_vm.smallCoinList, function(item) {
              return _c(
                "a-select-option",
                {
                  key: item.coinKindItemId,
                  attrs: { value: item.coinKindItemId }
                },
                [_vm._v(_vm._s(item.coinKindItemName))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "200px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                showSearch: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                dropdownMenuStyle: { "max-height": "500px" },
                placeholder: "版别"
              },
              on: { select: _vm.search },
              model: {
                value: _vm.params.coinVersionId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinVersionId", $$v)
                },
                expression: "params.coinVersionId"
              }
            },
            _vm._l(_vm.versionList, function(item) {
              return _c(
                "a-select-option",
                {
                  key: item.coinVersionId,
                  attrs: { value: item.coinVersionId }
                },
                [
                  _vm._v(
                    _vm._s(
                      "【" +
                        (item.level ? item.level : "无") +
                        "】 " +
                        item.coinVersionName +
                        "(" +
                        (item.size || 0) +
                        ")"
                    )
                  )
                ]
              )
            }),
            1
          ),
          _c("button", { staticClass: "search", on: { click: _vm.search } }, [
            _vm._v("搜索")
          ]),
          _c(
            "div",
            { staticClass: "change-box" },
            [
              _c(
                "button",
                {
                  staticClass: "search",
                  on: {
                    click: function($event) {
                      return _vm.uploadClick()
                    }
                  }
                },
                [_vm._v("上传")]
              ),
              _c(
                "a-radio-group",
                {
                  attrs: { "default-value": 0, "button-style": "solid" },
                  model: {
                    value: _vm.imageType,
                    callback: function($$v) {
                      _vm.imageType = $$v
                    },
                    expression: "imageType"
                  }
                },
                [
                  _c("a-radio-button", { attrs: { value: 0 } }, [
                    _vm._v(" 正面图 ")
                  ]),
                  _c("a-radio-button", { attrs: { value: 1 } }, [
                    _vm._v(" 背面图 ")
                  ])
                ],
                1
              ),
              false
                ? _c(
                    "button",
                    {
                      staticClass: "download-btn",
                      on: {
                        click: function($event) {
                          return _vm.handleDownload()
                        }
                      }
                    },
                    [_vm._v("对版准确度统计")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("ConfirmList", {
            attrs: { list: _vm.data, imageType: _vm.imageType },
            on: {
              delectSuccess: _vm.delectSuccess,
              editSuccess: _vm.editSuccess,
              smallVersion: function($event) {
                return _vm.handleShowSameVersion($event)
              },
              cutPicSuccess: function($event) {
                return _vm.handleCutPicSuccess($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("a-pagination", {
                attrs: {
                  pageSize: _vm.pagination.pageSize,
                  current: _vm.pagination.current,
                  total: _vm.pagination.total
                },
                on: {
                  "update:pageSize": function($event) {
                    return _vm.$set(_vm.pagination, "pageSize", $event)
                  },
                  "update:page-size": function($event) {
                    return _vm.$set(_vm.pagination, "pageSize", $event)
                  },
                  change: _vm.changePage
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("ConfirmAdd", { ref: "confirmAdd", on: { success: _vm.addSuccess } }),
      _c("ConfirmExportData", { ref: "export-data" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }